@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:wght@400;500;600;700&display=swap");

/* 

colors: #aff8db #121213

*/

/*
--- 01 TYPOGRAPHY SYSTEM

- Font sizes (px)
10 / 12 / 14 / 16 / 18 / 20 / 24 / 30 / 36 / 44 / 52 / 62 / 74 / 86 / 98

- Font weights
Default: 400
Medium: 500
Semi-bold: 600
Bold: 700

- Line heights
Default: 1
Small: 1.05
Medium: 1.2
Paragraph default: 1.6
Large: 1.8

- Letter spacing
-0.5px
0.75px

Default: 9px
Medium: 11px

--- 07 WHITESPACE

- Spacing system (px)
2 / 4 / 8 / 12 / 16 / 24 / 32 / 48 / 64 / 80 / 96 / 128
*/

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
}

body {
  font-family: "IBM Plex Mono", sans-serif;
  background-color: #121213;
  color: white;
  font-size: 1.8rem;
}

.app {
  max-width: 200rem;
  display: flex;
  flex-direction: column;
  gap: 24rem;
}

.navbar-background {
  /* background-color: #4d0743a1; */
  /* transition-duration: 0.5s;
  z-index: 1;
  font-weight: 600; */
}

.navbar {
  transform: translate(0, 20px);
  background-color: #23322c;
  margin: 0 auto;
  border-radius: 12px;
  font-weight: 600;
  color: white;
  position: sticky;

  display: flex;
  justify-content: center;
  gap: 4rem;
  top: 0;
  padding: 2rem 4rem;
  list-style: none;
  cursor: pointer;
  z-index: 1;
}

.navbar li .active {
  background-color: #070a09;

  border-radius: 12px;
}

.navbar .link {
  padding: 1rem 2rem;
}

.navbar .link:hover {
  background-color: #070a09;
  border-radius: 12px;
}

.hero {
  max-width: 130rem;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
}

.hero-header {
  display: flex;
  gap: 2rem;
  align-items: center;
}

.rocket {
  font-size: 3.2rem;
  cursor: pointer;
  right: 0;
}

.hero img {
  width: 14rem;
  border-radius: 50%;
}

.hero p {
  color: rgb(216, 215, 215);
  text-align: center;
  margin-bottom: 1.2rem;
}

.green-text {
  color: #aff8db;
}

.about {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  max-width: 130rem;
  margin: 0 auto;
}

.about p {
  line-height: 1.8;
  color: rgb(216, 215, 215);
  text-align: justify;
}

.skills {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  max-width: 130rem;
  margin: 0 auto;
}

h1 {
  font-size: 4.4rem;
  position: relative;
}

h2 {
  font-size: 3.6rem;
  margin-bottom: 2.4rem;
}

h3 {
  font-size: 2.8rem;
}

svg {
  display: block;
  width: 3.2rem;
  background-color: #aff8db;
  color: #121213;
  border-radius: 50%;
  stroke-width: 2.5;
  padding: 3px;
}

.skills-sections {
  display: flex;
  gap: 2rem;
}

.skills-sections p:first-child {
  color: #aff8db;
}

.projects-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  max-width: 130rem;
  margin: 0 auto;
}

.projects {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  justify-content: center;
}

.project {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 2rem 4rem;
  background-color: #8cc6af;
  color: #121213;
  width: 60rem;
}

.project p {
  text-align: justify;
  line-height: 1.8;
}

hr {
  border: 1px solid black;
}

.contact {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
  max-width: 120rem;
  margin: 0 auto;
  margin-bottom: 10rem;
}

.submited {
  width: 60rem;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 8rem;
  margin-bottom: 10rem;
}

.contact-infos {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 2rem;
  margin-bottom: 4.8rem;
}

input {
  padding: 2px;
  outline: none;
  border: none;
  background-color: transparent;
  border-bottom: 1px solid #aff8db;
  color: white;
  width: 30rem;
  font-size: 1.8rem;
}

/* input:focus {
  outline: 1px solid #aff8db;
} */

textarea {
  padding: 1rem;
  width: 100rem;
  height: 25rem;
  outline: none;
  border: 1px solid #aff8db;
  background-color: transparent;
  color: white;
  font-size: 1.8rem;
  margin-bottom: 2rem;
}

button {
  padding: 8px 18px;
  background-color: #aff8db;
  color: #121213;
  box-shadow: none;
  border: none;
  outline: none;
  cursor: pointer;
  font-size: 2.4rem;
}

button:hover {
  transform: scale(1.02);
  transition-duration: 0.3s;
}

.project a:link,
.project a:visited {
  text-decoration: none;
  padding: 8px 18px;
  background-color: #aff8db;
  color: #121213;
  box-shadow: none;
  border: none;
  outline: none;
  cursor: pointer;
  font-size: 2.4rem;
  width: 16rem;
  margin-top: auto;
  align-self: flex-end;
  background-color: #121213;
  color: white;
}

.project a:hover {
  transform: scale(1.02);
  transition-duration: 0.3s;
}

.social-media {
  display: flex;
  gap: 2rem;
}

.social-media img {
  width: 3.2rem;
  cursor: pointer;
}

.social-media img:hover {
  transform: translateY(3px);
  transition-duration: 0.3s;
}

.footer {
  max-width: 100rem;
  margin: 0 auto;
}

.footer p {
  font-size: 1.4rem;
  color: rgb(216, 215, 215);
  margin-bottom: 2rem;
}

.rocket-animation {
  animation: spin 8s;
  cursor: default;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  10% {
    transform: rotate(0deg);
  }

  40% {
    transform: rotate(-45deg);
  }

  50% {
    transform: translateY(-100px);
  }

  80% {
    transform: translateY(0px);
  }

  80% {
    transform: rotate(-45deg);
  }

  100% {
    transform: rotate(0deg);
  }
}
