/* 1400px */
@media (width <= 87em) {
  .about {
    max-width: 120rem;
  }
}

/* 1300px */
@media (width <= 81em) {
  .about {
    max-width: 90rem;
  }
}

/* 1100px */
@media (width <= 62em) {
  .about {
    max-width: 70rem;
  }

  h2 {
    font-size: 3rem;
  }

  textarea {
    width: 70rem;
  }
}

/* 800px */
@media (width <= 50em) {
  h2 {
    font-size: 3rem;
  }

  .navbar {
    font-size: 1.6rem;
    gap: 2rem;
  }

  .about {
    max-width: 60rem;
  }

  textarea {
    width: 60rem;
  }
}

/* 680px */
@media (width <= 43em) {
  .hero {
    max-width: 50rem;
  }

  .about {
    max-width: 70rem;
  }

  h1 {
    font-size: 3.6rem;
  }

  h2 {
    font-size: 3rem;
  }

  .navbar {
    font-size: 1.6rem;
    gap: 0.5rem;
  }

  .about {
    max-width: 50rem;
  }

  .project {
    width: 40rem;
  }

  .contact {
    max-width: 50rem;
  }

  .contact-infos {
    flex-direction: column;
    gap: 3rem;
  }

  textarea {
    width: 30rem;
  }
}

/* 500px */
@media (width <= 32em) {
  .navbar {
    font-size: 1.2rem;
    gap: 0.1rem;
  }

  .navbar .link {
    padding: 0.5rem 1rem;
  }

  .hero {
    max-width: 36rem;
  }

  h1 {
    font-size: 2.4rem;
  }

  h2 {
    font-size: 2rem;
  }

  p {
    font-size: 1.4rem;
  }

  .about {
    max-width: 36rem;
  }

  .projects {
    max-width: 36rem;
  }

  .project {
    width: 40rem;
  }

  .contact {
    max-width: 50rem;
  }

  .contact-infos {
    flex-direction: column;
    gap: 3rem;
  }

  textarea {
    width: 30rem;
  }

  .footer p {
    text-align: center;
  }
}
